import { defineStore } from 'pinia';

export const useEventStore = defineStore('eventStore', {
    state: () => ({
        markersWithEvents: [] as Array<any>,
        eventsWithMarkers: [] as Array<any>,
        validEventsIds: [] as Array<any>,
        validEventsOnMap: [] as Array<any>,
        invalidEventsIds: [] as Array<any>,
        showEvents: true as boolean,
        allEventsId: [] as Array<any>,
        eventNumberFilter: 0 as number,
    }),
    actions: {

    }
});