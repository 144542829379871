<template>
    <div class="search" v-show="(currentURL == '/scenarios/' && useMapStoreData().activePoi == null) || useMapStoreData().showMap">
      <div v-if="currentURL != '/scenarios/'">	
        <ul v-if="input.length > 0 && keyboardOpened && useSearchData().searchFilteredItems.length > 0" class="autocomplete">
            <li @click="goToMarker(item.properties.id)" v-for="item in useSearchData().searchFilteredItems" :key="item">{{ t(item.properties.id + '.title') }}</li>
        </ul>
      </div>

      <div :style="{minWidth:'12.1354166667vw',width: inputWidth, maxWidth:'29.7395833333vw'}" class="search-item" data-cy="search-item" @click="openSearch" :class="{active:searchOpened }">
        <span v-if="!searchOpened">{{t('general.search')}}</span>
        <input style="pointer-events:none" ref="inputSearch" :placeholder="t('general.search')" v-model="input" type="text">
          <div class="line"></div>
          <img class="glass"  :src="searchIcon" alt="">

          <img class="close" @click.stop="closeSearch" :src="closeIcon" alt="">
        </div>
        <div v-if="searchOpened">
          <keyboard 
            v-show="keyboardOpened"
            @closeSearch="toggleSearch" 
            @keyboardInput="keyboardInput" 
            @closeKeyboard="closeKeyboard"
          >
        </keyboard>
        </div>
    </div>



  </template>
  <script setup>
  import { ref,watch,onBeforeUnmount,computed } from 'vue'
  import { useMapStoreData,useSetMap,useCategoriesStore,useSearchData,useScreensaverStore } from '@/stores/index'
  import closeIcon from '@/assets/close-marker-dark.svg';
  import  searchIcon  from '@/assets/menu/search-icon.svg'; 
  import { useRoute } from 'vue-router'
  import Keyboard from '@/components/Keyboard.vue'
  // @ts-ignore
  import { useI18n } from 'vue-i18n'
  const searchOpened = ref(false);
  const keyboardOpened = ref(true);
  const input = ref("");
  const keyboard = null;
  const keyboardInputConst = ref("");
  const mapStore = useMapStoreData();
  const newSearch = ref("");
  const route = useRoute();
  const currentURL = ref(route.fullPath);
  const inputSearch = ref(null);
  const passCurrentInput = ref("");
  const { t } = useI18n();
  

  function goToMarker(id) {
    keyboardOpened.value = false;
    const title = t(id + '.title'); 
    mapStore.poiPopularity(id);
    input.value = title;
    useMapStoreData().activePoi = id;
    if(currentURL.value == "/scenarios/") {
      console.log("scenarios");
    }else {
      let map1 = {
        type: 'FeatureCollection',
        features: [] 
      }

      map1.features = mapStore.clusters.features.filter(e => {
          if(e.id == id){
            return e;
          }
      });
      
      useSetMap().setMapData(map1);

      if (useMapStoreData().markers && useMapStoreData().markers[id] && useMapStoreData().markers[id].geometry && useMapStoreData().markers[id].geometry.coordinates) {
          const clickedCoordinates = useMapStoreData().markers[id].geometry.coordinates;
          let zoom = 16;
          if (useSetMap().map.getZoom() > 16) {
            zoom = useSetMap().map.getZoom();
          }
        
          useSetMap().map.flyTo({
            center: [clickedCoordinates[0], clickedCoordinates[1]],
            zoom: zoom
          });
        }
    }
  }

function toggleSearch() {
    searchOpened.value = !searchOpened.value;
    if(searchOpened.value == false) {
      console.log("close!");
      resetSearch();
    }else {
      console.log("search open!");
    }
}

function openSearch() {
  console.log("openSearch");
  input.value = keyboardInputConst.value;
  passCurrentInput.value = input.value;
  searchOpened.value = true;
  keyboardOpened.value = true;
}

function closeSearch() {
  resetSearch();
}

function keyboardInput(keyinput) {
  input.value = keyinput;
  keyboardInputConst.value = keyinput;
}

function closeKeyboard() {
  console.log("closeKeyboard");
  keyboardOpened.value = false;
}

function resetSearch() {
  console.log("resetSearch");
    input.value = "";
    useSetMap().setAllMarkersData();
    useSearchData().searchFilteredItems = [];
    if(searchOpened) {
      searchOpened.value = false;
    }
    keyboardInputConst.value = "";
    keyboardOpened.value = true;
    useSearchData().activePoi = null;
}

const filterMarkers = (poiCategories) => {
  if(useCategoriesStore().activeFilters.length == 0) {
    return true;
  }
  const hasActiveFilter = poiCategories.some(category => useCategoriesStore().activeFilters.includes(category));
  return hasActiveFilter;
}




onBeforeUnmount(() => {
  resetSearch();
});

const inputWidth = computed(() => {
  const baseWidth = '18.2291666667vw'; // base width in vw
  if(searchOpened.value) {
    const charWidth = `${input.value.length}vw`; // width in ch units
    // If input has value, use charWidth, otherwise use baseWidth
    return input.value.length > 15 ? charWidth : baseWidth;
  }else {
    return '12.1354166667vw';
  }
  

});

  watch(() => input.value,
    (newInput, oldInput) => {

      useSearchData().searchFilteredItems = [];
      var isTagFound = false;
      var inArray = false;
      const tags = mapStore.dataSelectors.tags;

      for (const key in mapStore.markers) {
        if (mapStore.markers.hasOwnProperty(key)) {
          const feature = mapStore.markers[key];

        
          //drejc dodano iskanje po tagih
          isTagFound = false;
         
          for (let i = 0; i < tags.length; i++){

            inArray = false;
            if(Array.isArray(feature.properties.tags))
            for (const item of feature.properties.tags) {
               if (item == tags[i].id) {
                inArray = true;
               break;
             }
           }

            if(inArray 
                && (
                      tags[i].name.toLowerCase().includes(newInput.toLowerCase()) 
                      ||
                      (tags[i].locale != undefined && tags[i].locale[mapStore.currentLanguage] != undefined && (tags[i].locale[mapStore.currentLanguage].toLowerCase().includes(newInput.toLowerCase())) )
                   )
                ){
                  //console.log('isTagFound', feature.properties.title)
                  isTagFound = true;
                  break;
                 }
          }
          
          //drejc ne vem kako dodat v listo rezultatov
          const title = t(feature.properties.id + '.title')
          if(title.toLowerCase().includes(newInput.toLowerCase()) || isTagFound){
            newSearch.value = title;

            //console.log('proavamo dodat ' +title+ ' ker vsebuj '+newInput.toLowerCase() + ' tagfound '+ isTagFound)
            if(filterMarkers(feature.properties.categories)) {
              useSearchData().searchFilteredItems.push(feature);

            }
          }
        }
      }
  });

  watch(
    () => searchOpened,
    (newValue) => {
      if(newValue == null || newValue == false) {
        resetSearch();
      }
    }
  )

  watch(
    () => useScreensaverStore().showSreensaver,
    (newValue) => {
      if(newValue == true) {
        resetSearch()
      }
    }
  )
  </script>
    <style scoped lang="scss">
    @import '../assets/style/mixins.scss';
    @import '../assets/style/components/search.scss';
    </style>