<template>
  <div>
    <Header v-if="showCategories"  :setupHeader="setupDataHeader"></Header>
    <div class="wishing-well" v-if="showCategories">
      <div class="wishing-well-container">
        <div class="column"></div>
        <div class="column categories">
          <template
            v-for="([key, value], index) of Array.from(categoryHierarchy)"
          >
            <div
              v-if="mapStore.poiCategories.get(key).number != 0"
              class="categories-types"
              :class="[
                'style-' + key,
                showAllFilters.includes(key) ? 'active' : 'inactive',
              ]"
              @click="openFilter(key, $event)"
            >
              <img class="izrez" :src="izrez" alt="" /><img
                class="icon"
                :src="icons[key]"
                alt=""
              />{{ showCategoryName(key) }}
            </div>
          </template>
          <div 
          class="events event-category"
          @click="openFilter(key, $event)"
          :class="[
                'style-' + key,
                showAllFilters.includes(key) ? 'active' : 'inactive',
              ]"
          >
            <img class="izrez" :src="izrez" alt="" /><img
              class="icon"
              :src="eventsIcon"
              alt=""
            /> {{  t('categories.events') }}
          </div>
          <div :class="[
                showAllFilters.length > 0 ? 'activated' : 'disabled',
              ]" class="btn-container">
            <span class="next" @click="nextStep(2)">
                  {{ t('general.btn_forward') }}
              <svg
                width="27"
                height="24"
                viewBox="0 0 27 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.1524 0.910156L26.4649 10.7539C26.7578 11.0469 26.875 11.3984 26.875 11.75C26.875 12.1602 26.7578 12.5117 26.4649 12.8047L16.1524 22.6484C15.5664 23.1758 14.6875 23.1758 14.1602 22.5898C13.6328 22.0625 13.6328 21.125 14.2188 20.5977L22.0117 13.1562L2.08986 13.1562C1.26955 13.1562 0.683609 12.5703 0.683609 11.8086C0.683608 11.1055 1.26955 10.3437 2.08986 10.3437L22.0117 10.3437L14.2188 2.96094C13.6328 2.43359 13.6328 1.49609 14.1602 0.968749C14.6875 0.382812 15.5664 0.382812 16.1524 0.910156Z"
                  fill="#2E3D45"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Header from "@/components/Header.vue";
import { ref, onMounted, computed, onUnmounted } from "vue";
import { useCategoriesStore, useMapStoreData } from "@/stores/index";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import natureIcon from "@/assets/wishing-well/icons/narava.svg";
import kulturaIcon from "@/assets/wishing-well/icons/kultura.svg";
import sportsIcon from "@/assets/wishing-well/icons/sports.svg";
import tourismIcon from "@/assets/wishing-well/icons/tourism.svg";
import eventsIcon from "@/assets/wishing-well/icons/events.svg";
import arrowRight from "@/assets/common/arrow-right.svg";
import izrez from "@/assets/wishing-well/izrez.png";
import { Rive } from '@rive-app/canvas';


const route = useRoute();
const { t } = useI18n();
const mapStore = useCategoriesStore();
const mapStoreData = useMapStoreData();
let categoryHierarchy = ref(mapStore.categoryHierarchy);
let showAllFilters = ref([]);
let showCategories = ref(true);
const setupDataHeader = {
  centerIcon: true,
  orderFilter: false,
  search: false,
  backArrow: false,
  backArrowMap: true,
};

const icons = {
  80: natureIcon,
  82: kulturaIcon,
  81: sportsIcon,
  83: tourismIcon,
  84: eventsIcon,
};


const showCategoryName = (subcategories) => {
  const object = mapStoreData.dataSelectors.categories.find(item => item.id == subcategories);

  if (object.locale != undefined) {
    return object.locale[mapStoreData.currentLanguage];
  } else {
    return "No local";
  }
};

const openFilter = (key) => {
  if (showAllFilters.value.includes(key)) {
    showAllFilters.value.splice(showAllFilters.value.indexOf(key), 1);
  }else {
    showAllFilters.value.push(key);
  }
}

const nextStep = (step) => {
  if(showAllFilters.length == 0) {
    return;
  }
    switch (step) {
        case 1:

            break;
        case 2:
            showCategories.value =  false;
            useMapStoreData().showMap = true;
            useMapStoreData().hideMap = false;
            break
        case 3:
            break
        default:
            break
    }
}
</script>
<style scoped lang="scss">
@import "../assets/style/mixins.scss";
.wishing-well {
    position: absolute;
    top: 120px;
}
.wishing-well-container {
  display: flex;
  color: #000;
  height:100vh;
  margin-top:get-vw(50px);
  
  .column {
    width: 50vw;
  }
  .categories.column {
    font-size: get-vw(30px);
    font-family: "RobotoFlex";
    color: #fff;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;
    align-items: end;
    div {
        width: 100%;
      display: flex;
      align-items: center;
      font-weight: 600;
      padding: get-vw(25px) 0 get-vw(25px) get-vw(50px);
      position: relative;
      margin-bottom: get-vw(30px);
      transition: all 0.3s ease;
      img.izrez {
        width: auto;
        height: 100%;
        position: absolute;
        left: get-vw(-60px);
        padding: 0;
        border-radius: 0;
      }
      .icon {
        width: get-vw(60px);
        margin-right: get-vw(10px);
      }
    }
        .active {
            transition: all 0.3s ease;
            width:100%;
        }
        .inactive {
            transition: all 0.3s ease;
            width:80%;
        }
    .btn-container {
      align-items: center;
      display: flex;
      justify-items: center;
      justify-content: center;

      .next {
        display: flex;
        align-items: center;
        justify-content: center;
        border: get-vw(2px) solid #000;
        font-weight: 500;
        color: #000;
        border-radius: get-vw(50px);
        font-size: get-vw(30px);
        background-color: #fff;
        padding: get-vw(5px) get-vw(15px);
        svg {
            width:get-vw(27px);
            height:get-vw(24px);
          margin-left: get-vw(10px);
        }
      }

      &.disabled {
        .next {
          opacity: 0.5;
        }
      }
    }
  }
}

@media only screen and (orientation: portrait) {
    .wishing-well-container {
        .categories.column {
            justify-content: center;
        }
    }
}
</style>
