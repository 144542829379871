import { useSetMap,useMapStoreData } from '@/stores/index'

let intervalId;
var layersToAdd = [
  {
      'id': 'custom_landcover',
      'type': 'fill',
      'source': 'custom_tiles',
      'source-layer': 'landcover',
      'paint': {
          'fill-color': [
              'match',
              ['get', 'class'],
              'farmland', '#EDEFEF',
              'grass', '#EDEFEF',
              'meadow', '#EDEFEF',
              '#EDEFEF'
          ],
          'fill-opacity': 1
      }
  },
  {
      'id': 'custom_landuse',
      'type': 'fill',
      'source': 'custom_tiles',
      'source-layer': 'landuse',
      'paint': {
          'fill-color': [
              'match',
              ['get', 'class'],
              'residential', '#f2e5d4',
              'industrial', '#f2e5d4',
              'commercial', '#f2e5d4',
              '#EDEFEF'
          ],
          'fill-opacity': 1
      }
  },
  {
      'id': 'custom_water',
      'type': 'fill',
      'source': 'custom_tiles',
      'source-layer': 'water',
      'paint': {
          'fill-color': '#a0c8f0',
          'fill-opacity': 1
      }
  },
  {
      'id': 'custom_waterway',
      'type': 'line',
      'source': 'custom_tiles',
      'source-layer': 'waterway',
      'paint': {
          'line-color': '#a0c8f0',
          'line-width': 2
      }
  },
  {
      'id': 'custom_boundary',
      'type': 'line',
      'source': 'custom_tiles',
      'source-layer': 'boundary',
      'paint': {
          'line-color': '#a0c8f0',
          'line-width': 1,
          'line-dasharray': [2, 2]
      }
  },
  {
      'id': 'custom_building',
      'type': 'fill',
      'source': 'custom_tiles',
      'source-layer': 'building',
      'paint': {
          'fill-color': '#e0e0e0',
          'fill-opacity': 1
      }
  },
  {
      'id': 'custom_transportation',
      'type': 'line',
      'source': 'custom_tiles',
      'source-layer': 'transportation',
      'paint': {
          'line-color': '#ffffff',
          'line-width': 2
      }
  },
  {
      'id': 'custom_transportation_name',
      'type': 'symbol',
      'source': 'custom_tiles',
      'source-layer': 'transportation_name',
      'layout': {
          'text-field': '{name}',
          'text-size': 12
      },
      'paint': {
          'text-color': '#000000'
      }
  },
  {
      'id': 'custom_aeroway',
      'type': 'line',
      'source': 'custom_tiles',
      'source-layer': 'aeroway',
      'paint': {
          'line-color': '#c0c0c0',
          'line-width': 2
      }
  },
  {
      'id': 'custom_aerodrome_label',
      'type': 'symbol',
      'source': 'custom_tiles',
      'source-layer': 'aerodrome_label',
      'layout': {
          'text-field': '{name}',
          'text-size': 12
      },
      'paint': {
          'text-color': '#000000'
      }
  },
  {
      'id': 'custom_place',
      'type': 'symbol',
      'source': 'custom_tiles',
      'source-layer': 'place',
      'layout': {
          'text-field': '{name}',
          'text-size': 12
      },
      'paint': {
          'text-color': '#000000'
      }
  },
  {
      'id': 'custom_mountain_peak',
      'type': 'symbol',
      'source': 'custom_tiles',
      'source-layer': 'mountain_peak',
      'layout': {
          'text-field': '{name}',
          'text-size': 12
      },
      'paint': {
          'text-color': '#000000'
      }
  }
];

export async function isServerOnline(url) {
    return fetch(url, { method: 'HEAD', mode: 'no-cors' })
      .then(() =>  {
          useMapStoreData().isOnline = true;
          clearTimeout(intervalId);
      })
      .catch(() => {
        intervalId = setTimeout( function(){
          isServerOnline('https://miza.simpl.guide/');
        }, 3000);
        useMapStoreData().isOnline = false;
      });
  }

export function addOfflineMapSourceLayer() {
    if (!useSetMap().map.getSource('custom_tiles')) {
        // Add the source only if it does not already exist
        useSetMap().map.addSource('custom_tiles', {
          type: 'vector',
          tiles: [
            'http://localhost:8080/data/v3/{z}/{x}/{y}.pbf'
          ],
          minzoom: 0,
          maxzoom: 14
        });
      
        layersToAdd.forEach(function (layer) {
          useSetMap().map.addLayer(layer, 'clusters');
        });
      } else {
        console.log("Source 'custom_tiles' already exists");
      }
}

export function removeOfflineMapSourceLayer() {
  layersToAdd.forEach(function(layer) {
    if (useSetMap().map.getLayer(layer.id)) {
        map.removeLayer(layer.id);
    } else {
        console.log("Layer not found: ", layer.id);
    }
  });

  if (useSetMap().map.getSource('custom_tiles')) {
        useSetMap().map.removeSource('custom_tiles');
  } else {
      console.log("Source not found: custom_tiles");
  }
}