<template>
    <div class="container">
        <Header :setupHeader=setupDataHeader></Header>
        <video :poster="poster" @click="togglePlay" autoplay preload="metadata" ref="videoRef" class="video" 
        v-if="video != ''" controlslist="nodownload noplaybackrate" 
        disablepictureinpicture
         @ended="onVideoEndedOrPause"
        >
            <source :src="video" type="video/mp4">
        </video>
        <div class="controls">
            <span>{{t(useMapStoreData().routes[id]?.title)}}</span>
            <div @click="togglePlay">
                <img v-show="!isPlaying" :src="pauseIcon" alt="">
                <img v-show="isPlaying" :src="playIcon" alt="">
            </div>
        </div>
    </div>
</template>
<script setup>
import Header from '@/components/Header.vue'
import { ref, onMounted,computed,onUnmounted } from "vue";
import { useI18n } from "vue-i18n";
import { useMapStoreData,useScreensaverStore } from "@/stores/index";
import { useRoute } from 'vue-router';
import pauseIcon from '@/assets/pause-icon.svg';
import playIcon from '@/assets/play-icon.svg';

const { t } = useI18n();
const route = useRoute();
const id = computed(() => route.params.id);
const videoRef = ref(null);
const isPlaying = ref(true); 
const backgroundImage = computed(() => {
    return useMapStoreData().routes[id.value].thumb.hash + "." + useMapStoreData().routes[id.value].thumb.ext;
});
const video = computed(() => {
    return 'repo/' + useMapStoreData().destinationId + '/' + useMapStoreData().routes[id.value].content.media.hash + "." + useMapStoreData().routes[id.value].content.media.ext;
});

const poster = computed(() => {
    return 'repo/' + useMapStoreData().destinationId + '/' + useMapStoreData().routes[id.value].content.media.hash + ".png";
});
const setupDataHeader = {
  centerIcon: true,
  orderFilter: false,
  search: false,
  backArrow: false,
  backArrowMap: true,
  shortHeader: true
}

onMounted(() => {
    useMapStoreData().hideMap = true;
    useScreensaverStore().videoPlayingSomewhere = true;
});

onUnmounted(() => {
    useMapStoreData().hideMap = false;
    useScreensaverStore().videoPlayingSomewhere = false;
});

const togglePlay = () => {
    const videoElement = videoRef.value;
    //debugger;
    if (!videoElement) return;

    if (videoElement.paused || videoElement.ended) {
        videoElement.play();
        isPlaying.value = true;
        useScreensaverStore().videoPlayingSomewhere = true;
    } else {
        onVideoEndedOrPause()
    }
};

const onVideoEndedOrPause = () => {
    const videoElement = videoRef.value;
    console.log("pause or stop"); 
        videoElement.pause();
        isPlaying.value = false;
        useScreensaverStore().videoPlayingSomewhere = false;
}
</script>
<style scoped lang="scss">
@import '../assets/style/mixins.scss';
.container {
    width:100vw;
    height:100vh;
    background-size: cover;
    background-position: center;
    position: relative;

    .video {
        height: 100vh;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    .controls {
        position: absolute;
        bottom:get-vw(50px);
        left:get-vw(50px);
        z-index: 100;
        background: #fff;
        display: flex;
        align-items: center;
        border-radius: get-vw(50px);
        border:get-vw(2px) solid #2E3D45;
        padding:get-vw(10px) get-vw(20px);
        span {
            font-size: get-vw(30px);
            font-weight: 500;
        }
        button {
            font-size: 90px;
        }
        img {
            width: get-vw(30px);
            height: get-vw(30px);
            margin-left:get-vw(10px);
            margin-top: get-vw(7px);
        }
    }
}

@media only screen and (orientation: portrait) {
    .container {
        .video {
            height: 32vh;
            position: absolute;
            top: 30%;
            left: 50%;
            transform: translateX(-50%);
        }
        .controls {
            left:auto;
            right:get-vw(40px);
            padding:get-vw(20px) get-vw(30px);
            img {
                width:get-vw(50px);
                height:get-vw(50px);
            }
            span {
                font-size: get-vw(60px);
            }
        }
    }
}
</style>