import { defineStore } from 'pinia';
import { useSetMap,useMapStoreData,useEventStore } from '@/stores/index'

export const useFavoritesStore = defineStore('favoritesStore', {
        state: () => ({
            favorites: [] as any,
            favoritesCount: 0,
            favoritesChanged: false,
            favoritesGeoJson: [] as { type: string, properties: { category: string,id:number }, geometry: { type: string, coordinates: number[] } }[],
            qrCode: null as any,
            isOpenPanel: false,
        }),
        actions: {
            toggleFavorites(favorite: any) {
                if (this.favorites.includes(favorite)) {
                    this.favorites.splice(this.favorites.indexOf(favorite), 1);
                } else if (this.favorites.length < 10) {
                    this.favorites.push(favorite);
                }
                
                const markers: Record<string, any> = useMapStoreData().markers ?? {};
                let favoriteMarkers:Array<Record<string, any>> = [];
                this.favorites.forEach((id: string) => {
                    favoriteMarkers.push(markers[id]);
                });
                this.favoritesGeoJson = [];
                let favoritesGeoJson: { type: string, properties: { category: string }, geometry: { type: string, coordinates: number[] } }[] = [];
                if(favoriteMarkers.length > 0){
                    favoriteMarkers.forEach((marker: Record<string, any>) => {
                        let coordinates = marker.geometry.coordinates;
                        let category = marker.properties.categories[0];
                        
                        if(useEventStore().validEventsIds.includes(marker.properties.id)){
                            category = "favorited-event";
                        }
                        const object = {
                            type: "Feature",
                            properties: {
                                category:"favorites-" + category,
                                id: marker.properties.id,
                            },
                            geometry: {
                                type: "Point",
                                coordinates: [...coordinates], // Replace with actual longitude and latitude values
                            },
                        };
                        this.favoritesGeoJson.push(object);
                        favoritesGeoJson.push(object);
                    });
                }

                if(this.favoritesGeoJson.length == 0){
                    useSetMap().deletePath('favoritedPath');
                }

                useSetMap().map.getSource('favoritesSource').setData({
                    type: "FeatureCollection",
                    features: this.favoritesGeoJson
                });
            },
            deleteAll() {
                this.favorites = [];
                useSetMap().map.getSource('favoritesSource').setData({
                    type: "FeatureCollection",
                    features: {type: "FeatureCollection", features: []}
                });
                useSetMap().deletePath('favoritedPath');
            },
            closeFavoritesPanel() {
                this.isOpenPanel = false;
            },
            
            async orderFavorites(api:string, start: any){
                let pois :Array<Record<string, any>> = [];
                //console.log(this.favorites);
   
                pois.push( {id: '0', lng: start[0] , lat: start[1]});


                const markers: Record<string, any> = useMapStoreData().markers ?? {};

                this.favorites.forEach((id: string) => {
                    pois.push( {id: markers[id].properties.id, 
                        lng: markers[id].geometry.coordinates[0] , 
                        lat: markers[id].geometry.coordinates[1] } 
                 );
                });

               
                console.log(pois);
                let saveResponseText;
                try{
                    let jsonRequest = this.formatRequest('LocationRequests', 'optimizeRoute', { pois: pois })
                    const saveResponse = await fetch(api, {
                    method: "POST",
                    body: jsonRequest,
                    credentials: 'omit', // 'omit' is equivalent to xhrFields: {withCredentials: false}
                    signal: AbortSignal.timeout(7000)
                    });
                    saveResponseText = await saveResponse.text();
                }catch(e){
                  return null
                }
                let res = null;
                try{
                    res = JSON.parse(saveResponseText);
                    if(res.success != undefined && res.success.order != undefined && Array.isArray(res.success.order)){
                        if(res.success.order.length == this.favorites.length){
                            this.favorites = res.success.order;
                            console.log('zamenjali vrstni red');
                        }
                    }else console.log('ne morem  zamenjali vrstni red ' +  Array.isArray(res.success.order));
                }catch(e){
                    console.log(' mne morem parsat: '+saveResponseText);
                }
                return res;
            },
            
            calculatePathStart(coordinates: any, start: any){
                const minDist = 0.000001;
                //preverimo če je krozna
                const starEbdDist =  this.getDistance(coordinates[0],coordinates[coordinates.length-1] )
                console.log('starEbdDist',starEbdDist)


                //poščemo najbližjo
                let nearestCoord = coordinates[0];
                let nearestDistFactor =  this.getDistanceFactor(coordinates[0], start);
                let curDistFactor;
                for(let i = 1; i < coordinates.length; i++){
                    curDistFactor =  this.getDistanceFactor(coordinates[i], start);
                    if(curDistFactor < nearestDistFactor){
                        nearestCoord = coordinates[i];
                        nearestDistFactor = curDistFactor;
                    }
                }

                const starttoPathDist =  this.getDistance(nearestCoord,start )
                console.log('starttoPathDist',starttoPathDist)


                console.log('coordinates', coordinates);
            },
              
              
            getDistanceFactor(coord1: any, coord2: any){       
                return (coord1[0]-coord2[0])**2  + (coord1[1]-coord2[1])**2;
            },
            getDistance(coord1: any, coord2: any){       
                const DEG_TO_RAD = Math.PI / 180;
                const R = 6371e3; // Earth's radius in meters
              
                const dLat = (coord2[1] - coord1[1]) * DEG_TO_RAD;
                const dLon = (coord2[0] - coord1[0]) * DEG_TO_RAD;
              
                const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                          Math.cos( coord1[1] * DEG_TO_RAD) * Math.cos(coord2[1] * DEG_TO_RAD) *
                          Math.sin(dLon / 2) * Math.sin(dLon / 2);
              
                const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
              
                return R * c;
            },
            formatRequest  (backinterface: string, method:string, parameters: any){
                var request =  {interface: backinterface, method: method, parameters: parameters}              
                var obj = {request: request};
              
                //if (this.token.length > 0) obj.token = this.token;
                return JSON.stringify(obj);
            }
            
        },    
    })

