<template>
    <div class="dropdown">
        <div @click="toggleDropdown" class="dropdown-toggle">
        <svg v-show="!showDropdownOrder" class="sorting-icon" width="27" height="23" viewBox="0 0 27 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.375 2.375C0.375 1.37891 1.19531 0.5 2.25 0.5H24.75C25.7461 0.5 26.625 1.37891 26.625 2.375C26.625 3.42969 25.7461 4.25 24.75 4.25H2.25C1.19531 4.25 0.375 3.42969 0.375 2.375ZM4.125 11.75C4.125 10.7539 4.94531 9.875 6 9.875H21C21.9961 9.875 22.875 10.7539 22.875 11.75C22.875 12.8047 21.9961 13.625 21 13.625H6C4.94531 13.625 4.125 12.8047 4.125 11.75ZM15.375 23H11.625C10.5703 23 9.75 22.1797 9.75 21.125C9.75 20.1289 10.5703 19.25 11.625 19.25H15.375C16.3711 19.25 17.25 20.1289 17.25 21.125C17.25 22.1797 16.3711 23 15.375 23Z" fill="#2E3D45"/>
        </svg>

            <img class="close-icon" v-show="showDropdownOrder" :src="closeIcon" alt="">
            <div class="line"></div>
            <div>{{ t(selectedOption.localization) }}</div>

        </div>
        <div v-show="showDropdownOrder" class="line-between"></div>
        <ul v-show="showDropdownOrder" class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <template v-for="option in dropdownOptions">
                <li @click="selectOption(option)" v-show="option.key != selectedOption.key">{{ t(option.localization) }}</li>
            </template>
        </ul>
    </div>
</template>
<script setup>
import { ref,onMounted } from 'vue';
import {useScenariosStore,useMapStoreData} from '@/stores/index'
import closeIcon from '@/assets/close-marker-dark.svg';
import { useI18n } from 'vue-i18n'

const { t } = useI18n();
const showDropdownOrder = ref(false);
const dropdownOptions = useScenariosStore().dropdownSortingOptions;
const selectedOption = ref(useScenariosStore().defaultSorting);

const toggleDropdown = () => {
    showDropdownOrder.value = !showDropdownOrder.value;
};

const selectOption = (option) => {
    selectedOption.value = option;
    showDropdownOrder.value = false;
    useScenariosStore().populateSortedMarkers(option.key);
};

onMounted(() => {
});
</script>
<style  scoped lang="scss">
@import "../assets/style/mixins.scss";
    .dropdown {
        min-width:get-vw(229px);
        font-size: 1.3020833333vw;
        padding:0 get-vw(20px);
        position: relative;
        display: inline-block;
        display:flex;
        align-items: center;
        border-radius: 2.8645833333vw;
        border: get-vw(2px) solid #2E3D45;
        margin-left:get-vw(25px);
        justify-content: left;

        .sorting-icon {
            width:get-vw(27px);
            height:get-vw(27px);
        }
        .close-icon {
            width:get-vw(28px);
            height:get-vw(28px);
        }
        .line {
                width: 0.1041666667vw;
                height: 1.8229166667vw;
                background-color: #304C5D;
                margin: 0 0.8333333333vw;
            }
        .dropdown-toggle {
            z-index: 1;
            display: flex;
            align-items: center;
            position: relative;
        }
    }

    .line-between {
                position:absolute;
                bottom:-5px;
                left:get-vw(20px);
                display:block;
                width:83%;
                height:get-vw(2px);
                background-color: #304C5D;
                z-index:1;
            }
    .dropdown-menu {
        width: 102%;
        left: get-vw(-2px);
        top:get-vw(-2px);
        padding:0;
        padding-top:get-vw(60px);
        border: 0.1041666667vw solid #2E3D45;
        //border-top-color: transparent;
        position: absolute;
        background-color: #fff;
        border-radius: 1.464583vw;
    }
    .dropdown-menu li {
        display: block;
        padding:0 get-vw(85px);
    }
    .dropdown-menu li a {
        display: block;
        text-decoration: none;
        color: #000;
    }
    .dropdown-menu li a {
        display: block;
        text-decoration: none;
        color: #000;
    }
    .dropdown-menu li a {
        display: block;
        text-decoration: none;
        color: #000;
    }
    @media only screen and (orientation: portrait) {

            .line-between {
                display: none;
            }
        
        .dropdown-menu {
            padding-top:get-vw(110px);
            position: absolute;
        background-color: #fff;
        top: auto;
        bottom: 100%;
        padding: 0;
            li {
                padding:get-vw(5px) get-vw(10px);
                margin-bottom: get-vw(8px);
            }
        }
    
    }
</style>