<template>
  <img :src="qrCodeSrc" class="qrCode-img" alt="QR Code" />
</template>

<script setup>
import { ref, watchEffect } from 'vue';
import QRCode from 'qrcode';
import {useFavoritesStore} from '@/stores/index'
const props = defineProps({
  text: String,
  showQR: Boolean
});
const url = false; // Set to true if you want to render with canvas
const qrCodeSrc = ref('');
const qrCanvas = ref(null);

watchEffect(() => {
  if (props.text != '') {
    console.log("Generating QR code",props.text);
    generateQR(props.text);
  }
});

const generateQR = async (text) => {

  if (!text) return; // Do not generate if text is not provided

  try {
    if (url) {
      await QRCode.toCanvas(qrCanvas.value, text, { width: 200, errorCorrectionLevel: 'H' });
    } else {
      qrCodeSrc.value = await QRCode.toDataURL(text, { width: 200, errorCorrectionLevel: 'H' });
      useFavoritesStore().qrCode = qrCodeSrc.value;
    }
  } catch (error) {
    console.error('Error generating QR code', error);
  }
};
</script>
<style scoped lang="scss">
@import '../assets/style/mixins.scss';
@import '../assets/style/components/qrCodeGenerator.scss';
</style>