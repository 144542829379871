<template>
        <div class="keyboard-container">
      <div class="keyboard-container-custom">
        <div class='keyboard-map'></div>
      </div>
    </div>
</template>

<script setup>
  import { ref, onMounted,onDeactivated,computed} from 'vue'
  import { useSetMap } from '@/stores/index'
  import { useRoute } from 'vue-router';
  import Keyboard from "simple-keyboard";
  import "simple-keyboard/build/css/index.css";

  let keyboard = null;
  let input = ref("");
  const route = useRoute();
  const pathRoute = computed(() => route.path == '/scenarios/');
  
  const emit = defineEmits(['closeSearch','keyboardInput','closeKeyboard']);

  onMounted(() => {
      keyboard = new Keyboard('keyboard-map', {
        onChange: inputchange => {
        input.value = inputchange;
        emit('keyboardInput',input.value);
      },
      onKeyPress: button => {
        if(input.value.length == 1 && button == "{bksp}") {
          handleShift();
        }else if (input.value.length == 0 && button == "{bksp}") {
          emit('closeSearch');
        }else {
          if(input.value.length == 0 && button != "{enter}") {
            handleShift();
          }
        }
        if (button === "{shift}" || button === "{lock}") handleShift();
        if(button == "{enter}") {
          if(input.value.length > 0) {
            emit('closeKeyboard');
            useSetMap().searchMapFilter();
          }
        }
      }
      });

      
      if(pathRoute.value) {
        keyboard.setOptions({
            layoutName: "shift",
            layout: {
                'default': [
                    '1 2 3 4 5 6 7 8 9 0',
                    'q w e r t y u i o p š {bksp}',
                    'a s d f g h j k l č ž',
                    '{shift} z x c v b n m',
                    '{space}'
                ],
                'shift': [
                    '1 2 3 4 5 6 7 8 9 0',
                    'Q W E R T Y U I O P Š {bksp}',
                    'A S D F G H J K L Č Ž',
                    '{shift} Z X C V B N M',
                    '{space}'
                ]
            },
            display: {
                '{bksp}': 'Del',
                '{space}': 'Space',
                '{shift}': ' '
            }
        });
      }else {
        
        keyboard.setOptions({
            layoutName: "shift",
            layout: {
                'default': [
                    '1 2 3 4 5 6 7 8 9 0 {enter}',
                    'q w e r t y u i o p š {bksp}',
                    'a s d f g h j k l č ž',
                    '{shift} z x c v b n m',
                    '{space}'
                ],
                'shift': [
                    '1 2 3 4 5 6 7 8 9 0 {enter}',
                    'Q W E R T Y U I O P Š {bksp}',
                    'A S D F G H J K L Č Ž',
                    '{shift} Z X C V B N M',
                    '{space}'
                ]
            },
            display: {
                '{bksp}': 'Del',
                '{enter}': ' ',
                '{space}': 'Space',
                '{shift}': ' '
            }
        });
      }
      
  });

  onDeactivated(() => {
    if(keyboard) {
      keyboard.destroy();
    }
  });
 
const handleShift = () => {
    let currentLayout = keyboard.options.layoutName;
    let shiftToggle = currentLayout === "default" ? "shift" : "default";
  
    keyboard.setOptions({
      layoutName: shiftToggle
    });
  };

</script>
<style scoped lang="scss">
    @import '../assets/style/mixins.scss';
    .keyboard-container-custom {
      position: absolute;
      width: get-vw(770px);
      left: 50%;
      //height: 100vh;
      /* transform: translate(10px, 10px); */
      transform: translateX(-50%);
      bottom: -92.2vh;
      //transition: all 1s;
          &.active {
            left: 50%;
            bottom: -92.2vh;
          }
      }
      @media only screen and (orientation: portrait) {
        .keyboard-container {
          position:absolute;
          bottom: 18.8vh;
          left: 50%;
        }
        .keyboard-container-custom {
          width: get-vw(1100px);
          bottom: 0px;
          left: 50%;
        }
      }

</style>