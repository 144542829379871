import { defineStore } from 'pinia'
import { useMapStoreData,useCategoriesStore,useSearchData,useEventStore } from '@/stores/index'
import mapboxgl from 'mapbox-gl';

export const useSetMap = defineStore('setMap', {
    state: () => ({
      map: null as any,
      layoutName: null as any,
      favoritedPathName: null as any,
      markerPathName: null as any,
      allMapData: null as any,
      mapLoader: true,
    }),
    actions: {
      // since we rely on `this`, we cannot use an arrow function
      setMapBox(mapbox:any) {
        this.map = mapbox;
      },
      fullMapReset() {
        useMapStoreData().setActivePoi(null);
        useMapStoreData().showMap = false;
        this.centerMapbox();
        this.deletePath('markerPath');
        this.deletePath('favoritedPath');
      },
      createPath(name:any,pathsource: any,type:string) {
        this.deletePath(type);
        if(type == 'markerPath') {
          this.markerPathName = name;
        }else if(type == 'favoritedPath') {
          this.favoritedPathName = name;
        }
        this.layoutName = name
        const color = getComputedStyle(document.documentElement).getPropertyValue('--nature-color-start').trim();        
        this.map.addLayer({
          id: this.layoutName,
          type: 'line',
          source: {
              type: 'geojson',
              data: pathsource 
          },
          layout: {
              'line-join': 'round',
              'line-cap': 'round'
          },
          paint: {
            'line-width': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0, 2, 
              10, 4,
              22, 10
          ],
          //'line-blur': 10.5,
          'line-color': color
          }
      });
      },
      deletePath(type:string = 'markerPath') {
        const layout = type == 'markerPath' ? this.markerPathName : this.favoritedPathName;  
        if(this.map.getLayer(layout) != undefined) {
          this.map.removeLayer(layout);
          this.map.removeSource(layout);
        }
      },
      setMapData(data:any) {
        this.map.getSource('clustering').setData(data);
      },
      setAllMarkersData() {
        if(this.map != null && this.map.getSource('clustering') != undefined) {
          let validEvents = useEventStore().validEventsOnMap.filter(value => !useEventStore().invalidEventsIds.includes(value));
          useEventStore().eventNumberFilter = validEvents.length;
          if((useMapStoreData().clusters as any).features.length > 0) {
            let resultEvents = (useMapStoreData().clusters as any).features.filter((cluster: any) => { 
              return !useEventStore().invalidEventsIds.includes(cluster.properties.id)
            });
            if(useCategoriesStore().defaultActiveFiltersParentsId.length > 0) {
              resultEvents = (resultEvents as any).filter((cluster: any) => { 
                return useCategoriesStore().defaultActiveFiltersParentsId.includes(cluster.properties.categories[0])
              });
            }
            let map1 = {
              type: 'FeatureCollection',
              features: resultEvents
            }
            this.map.getSource('clustering').setData(map1);
          }
        }
      },
      searchMapFilter() {
        if((useMapStoreData().clusters as any).features.length > 0) {
          let result = (useMapStoreData().clusters as any).features.filter((item: any) => useSearchData().searchFilter(item.id,item.properties.categories));
          
          let source = this.map.getSource('closeSourceMarker')._data;
          let containsSourceId = result.some((item: any) => item.id === source.id);
          
          //if poi is opened and is not in the search result, close marker from the map
          if(!containsSourceId) {
            useMapStoreData().setActivePoi(null);
          }

          let map1 = {
            type: 'FeatureCollection',
            features: result
          }
          this.map.getSource('clustering').setData(map1);

        let sourceCluster = this.map.getSource('clustering');

        this.moveToFilterBounds(sourceCluster);
      }
        //this.map.getSource('clustering').setData(useMapStoreData().clusters);
      },
      returnFilteredMarkersMap() { 
        let result:any = '';
        let resultEventsWith:any = '';
        let numberOfEvents = 0;
        if(useSearchData().searchFilteredItems.length > 0) {
          result = useSearchData().searchFilteredItems.filter((item: any) => useCategoriesStore().filterMarkersByCategory(item.properties.categories));
        }else {
          result = (useMapStoreData().clusters as any).features.filter((item: any) => useCategoriesStore().filterMarkersByCategory(item.properties.categories));
        }

        if(!useEventStore().showEvents) {
          let allEvent = useEventStore().validEventsOnMap;
          result = result.filter((item: any) => !allEvent.includes(item.id));
        }

        resultEventsWith = result.filter((cluster: any) => { 
          return !useEventStore().invalidEventsIds.includes(cluster.properties.id)
        });
    
        resultEventsWith.forEach((item: any) => {
          if(item.properties.type == 'event') {
            numberOfEvents++;
          }
        });

        useEventStore().eventNumberFilter = numberOfEvents;

        let map1 = {
          type: 'FeatureCollection',
          features: resultEventsWith
        }
        this.map.getSource('clustering').setData(map1);
      },
      moveToFilterBounds(sourceCluster:any) {
        if (sourceCluster.type === 'geojson') {
          let data = sourceCluster._data;
        
          if (data.type === 'FeatureCollection') {
            let markers = data.features;
            if(markers.length == 0) {
              //this.centerMapbox();
              return;
            }
            let bounds = markers.reduce(function(bounds:any, marker:any) {
              return bounds.extend(marker.geometry.coordinates);
            }, new mapboxgl.LngLatBounds(markers[0].geometry.coordinates, markers[0].geometry.coordinates));
        
            this.map.fitBounds(bounds, {
              padding: 250,  
              maxZoom: 14  
            });
          }
        } else if (Array.isArray(sourceCluster)) {
          let bounds = sourceCluster.reduce(function(bounds, marker) {
            return bounds.extend(marker.geometry.coordinates);
        }, new mapboxgl.LngLatBounds(sourceCluster[0].geometry.coordinates, sourceCluster[0].geometry.coordinates));
  
        // Adjust the map view to fit the bounding box
        this.map.fitBounds(bounds, {
          padding: 250,  // padding around the box for better view
          maxZoom: 18  // this can be set to ensure the map doesn't zoom in too far
        });
        }
      }
    },
    getters:{
      centerMapbox: (state) => () => {
        let options:any = useMapStoreData().options;

        if(options != null) {
          console.log("useMapStoreData().isOnline: ",useMapStoreData().isOnline);
          if(useMapStoreData().isOnline) {
            state.map.flyTo({
              center: [options['lng'], options['lat']],
              zoom: options.zoom,
              essential: true // this animation is considered essential with respect to prefers-reduced-motion
            });
          }
        }
      },
    }
  })
