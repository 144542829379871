<template>
<div class="printing-map-container">  
    <div id="printing-map"></div>
    <div id="printing-adventure-template" class="A4" style="">
        <div class="second-page-adventure">
        <div class="left-column">
          <h1>{{ t('printing.origamititle') }}</h1>

          <div class="intro"><b>{{ t('printing.origamititle') }}</b>&nbsp;<span>{{ t('printing.origamiintro') }}</span></div>
          <div class="description">{{ t('printing.origamidescription') }}</div>
          <img class="origami" :src="origamiSrc" alt="">
          <img class="scissors-2" :src="scissors" alt="">
        </div>
        <div class="middle-column">
            <div class="poi-item">
                <div class="marker-icon-container">
                    <img :src="currentLocation" alt="">
                </div>
                <div class="info">
                    <div class="address">{{ t('general.you_are_here') }}</div>
                    <div class="title">{{ t('general.starting_position') }}</div>
                </div>
            </div>
            <div v-for="(marker, index) in usePrintingStore().printMarkersSource" :key="index">
                <div class="poi-item">
                    <div class="marker-icon-container">
                        <img class="marker-icon" :src="marker.categoryImage" alt="">
                    </div>
                    <div class="info">
                        <div v-if="marker.address != ''" class="address">{{ marker.address }}</div>
                        <div v-if="marker.title != ''" class="title">{{ marker.title }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="right-column">
            <h1>Ime poti!</h1>
          <div  class="images">
            <div v-for="(marker, index) in usePrintingStore().printMarkersSource" 
            :key="index" class="bgi"  
            :style="{backgroundImage:`url(${marker.image[0]})`}"></div>
            <div class="qrCode">
                <img :src="useFavoritesStore().qrCode" alt="">
            </div>
         </div>
         <div class="logos">
            <div class="logo">
              <img class="simpl-logo" :src="simplLogo" alt="">
            </div>
            <div class="logo">
              <img class="artrebel-logo" :src="artRebelLogo" alt="">
            </div>
            <div class="logo">
              <img class="feelSlo-logo" :src="feelSloveniaLogo" alt="">
            </div>
            <div class="logo">
              <img class="evropska-logo" :src="evropskaUnijaLogo" alt="">
            </div>
            <div class="logo">
              <img class="roglaPohorje-logo" :src="roglaPohorjeLogo" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
</template>
<script setup>
import 'mapbox-gl/dist/mapbox-gl.css';
import { ref } from "vue";
import { usePrintingStore,useFavoritesStore } from "@/stores/index";
import { useI18n } from "vue-i18n";
import currentLocation from '@/assets/current-location-icon.svg'

import simplLogo from '@/assets/logo.svg';
import artRebelLogo from '@/assets/printing/artRebel-logo.svg';
import feelSloveniaLogo from '@/assets/printing/feelSlovenia.svg';
import evropskaUnijaLogo from '@/assets/printing/evropska-unija-logo.svg';
import roglaPohorjeLogo from '@/assets/printing/rogla-pohorje-logo.svg';
import scissors from '@/assets/printing/scissors.svg';
import origamiGroup1 from '@/assets/printing/origami-group-0.svg';
import origamiGroup2 from '@/assets/printing/origami-group-1.svg';
import origamiGroup3 from '@/assets/printing/origami-group-2.svg';
import origamiGroup4 from '@/assets/printing/origami-group-3.svg';
 
const { t } = useI18n();
const origamiArray = [origamiGroup1,origamiGroup2,origamiGroup3,origamiGroup4];
const origamiSrc = ref(origamiArray[Math.floor(Math.random() * 4)]);
</script>   
<style scoped lang="scss">
@import "../../assets/style/mixins.scss";
.printing-map-container {
    //pointer-events:none;
    .button {
        position: absolute;
        z-index: 999;
        top:0;
    }
}
#printing-map {
    position: absolute;
    top:-9999px;
    width: 960px;
    height: 960px;
}

#printing-adventure-template {
    top:0;
    z-index:999;
}

#printing-adventure-template {
    position: relative;
    pointer-events: none;
    /*top:100px;
    left:200px;*/
    z-index: -9999;
    background-color: #fff;
    .first-page-adventure {
        display: flex;
        width:1582px;
        height: 1118px;
        position: relative;
        //display: none;

        .path-img-container {
            img {
                width: 1118px;
            }
        }
        .right-container {
            padding: 40px;
            border-left: 2px solid #000;
            border-right:0;
            border-bottom:0;
            border-top:0;
            border-style: dotted;
            .line {
                width:100%;
                display: block;
                height:2px;
                background-color: #4CC8CD;
                margin-bottom: 40px;
                margin-top: 28px;            
            }
            .scissors {
                position: absolute;
                bottom: 31px;
                right: 455px;
            }
            h1 {
                font-size: 64px;
                margin-bottom: 0;
            }
            .intro {
                font-size: 21px;
                margin-bottom: 27px;
            }
            .description {
                color:#424242;
                font-size: 16px;
            }
            .qr-code {
                margin-top: 190px;
                svg {
                    display: block;
                    margin: 0 auto;
                }
                img {
                    width: 166px;
                    margin: 0 auto;
                }
            }
        }
    }

    .second-page-adventure {
        display: flex;
        height: 1118px;
        h1 {
            padding-top: 60px;
            font-size: 64px;
            font-family: "RobotoFlex", sans-serif;
        }

        .intro {
            font-family: "RobotoFlex", sans-serif;
            font-size: 21px;
            font-weight: 400;
            margin-bottom: 20px;
        }

        .description {
            font-size: 16px;
            color: #424242;
            line-height: 21px;
            margin-bottom: 20px;
        }

        .left-column {
            width:639px;
            padding-left: 30px;
            box-sizing: border-box;
            padding-bottom: 57px;
            padding-right: 30px;
            border-right: 2px dotted #000;
            border-top: 0;
            border-left: 0;
            border-bottom: 0;

            .origami {
                height: 565px;
                position: absolute;
            }

            .scissors-2 {
                left: 505px;
                position: absolute;
                top: 2180px;
            }
        }
        .middle-column {
            width:559px;
            flex-direction: row;
            padding-top: 43px;
            padding-left: 30px;
            padding-right: 30px;
            border-right: 2px dotted #000;
            border-top: 0;
            border-left: 0;
            border-bottom: 0;
            .starting-point {
              /*border-top:get-vw(2px) solid #2E3D45;
              border-bottom:get-vw(2px) solid #2E3D45;
              border-right:get-vw(2px) solid #2E3D45;
              border-radius: get-vw(40px) get-vw(20px) get-vw(20px) 0px;*/
              display: flex;
              align-items: center;
              .content {
                padding-top:0;
              }
              img {
                width: 38px;
                margin-top: 0px;
              }
            }
            .marker-icon-container {
                img {
                    width: 38px;
                    margin-top: 0px;
                }
            }
            .poi-item {
                width:494px;
                display: flex;
                justify-content: start;
                align-items: center;
                margin: 0 auto;
                padding:20px 0;

                .title {
                    font-size: 21px;
                    font-weight: 700;
                }
                .address {
                    font-size: 16px;  
                    margin-bottom: 7px;  
                }
                .schedule {
                    font-size: 13px;
                    width: 266px;
                }
                .icon-poi {
                    width:45px;
                    height: 45px;
                    border-radius: 50%;
                    position: relative;
                    margin-right: 10px;
                    svg {
                        width: 26px;
                        margin: 0 auto;
                        position: absolute;
                        top:50%;
                        left:50%;
                        transform: translate(-50%,-50%);
                    }
                }
                .icon-83 {
                    background-color: var(--turism-color);
                } 
                .icon-80 {
                    background-color: var(--nature-color);
                }
                .icon-81 {
                    background-color: var(--sport-color);
                }
                .icon-82 {
                    background-color: var(--culture-color);
                }
            }


        }

        .right-column {
            width:558px;
            padding-left: 80px;
            .qrCode {
                position: absolute;
                right: 0;
                bottom: 70px;
            }
            .logos {
                display: flex;
                justify-content: space-around;
                //padding-top: 38px;

                .simpl-logo {
                    width:56px;
                }
                .artrebel-logo {
                    width:40px;
                }

                .feelSlo-logo {
                    width:48px;
                }

                .evropska-logo {
                    width:55px;
                }
                .roglaPohorje-logo {
                    width: 42px;
                }
            }
            .images {
                display: flex;
                flex-direction: column;
                gap: 26px;
                height: 909px;
                padding-top: 53px;
                padding-bottom: 26px;
                align-items: stretch;

                .bgi {
                    height: 100%;
                    background-position: center;
                }
            }
        }
    }

    &.A4 {
        .first-page-adventure {
            display: flex;
            width: 1120px;
            height: 794px;
            position: relative;
            //display: none;
    
            .path-img-container {
                img {
                    width: 790px;
                }
            }
            .right-container {
                padding: 20px;
                border-left: 2px solid #000;
                border-right:0;
                border-bottom:0;
                border-top:0;
                border-style: dotted;
                .line {
                    width:100%;
                    display: block;
                    height:2px;
                    background-color: #4CC8CD;
                    margin-bottom: 40px;
                    margin-top: 28px;            
                }
                .scissors {
                    position: absolute;
                    bottom: 31px;
                    left: 783px;
                }
                h1 {
                    font-family: "RobotoFlex", sans-serif;
                    font-size: 48px;
                    margin-bottom: 0;
                }
                .intro {
                    font-size: 16px;
                    margin-bottom: 19px;
                }
                .description {
                    color:#424242;
                    font-size: 12px
                }
                .qr-code {
                    margin-top: 150px;
                    svg {
                        display: block;
                        margin: 0 auto;
                    }
                    img {
                        width: 94px;
                        margin: 0 auto;
                    }
                }
            }
        }
    
        .second-page-adventure {
            display: flex;
            width: 1120px;
            height: 794px;
            position: relative;
            h1 {
                padding-top: 40px;
                font-size: 48px;
                font-family: "RobotoFlex", sans-serif;
                font-weight: 700;
                line-height: 62px;
            }
    
            .intro {
                font-family: "RobotoFlex", sans-serif;
                font-size: 16px;
                font-weight: 400;
                margin-bottom: 20px;
                margin-top: 20px;
            }
    
            .description {
                font-size: 12px;
                color: #424242;
                line-height: 21px;
                margin-bottom: 20px;
            }
    
            .left-column {
                width:373px;
                padding-left: 30px;
                box-sizing: border-box;
                padding-bottom: 37px;
                padding-right: 30px;
                border-right: 2px dotted #000;
                border-top: 0;
                border-left: 0;
                border-bottom: 0;
    
                .origami {
                    height: 401px;
                    position: absolute;
                }
    
                .scissors-2 {
                    left: 363px;
                    position: absolute;
                    top: 743px;
                }
            }
            .middle-column {
                width:373px;
                flex-direction: row;
                padding-top: 32px;
                padding-left: 30px;
                padding-right: 23px;
                border-right: 2px dotted #000;
                border-top: 0;
                border-left: 0;
                border-bottom: 0;
    
                .marker-icon-container {
                    margin-right: 35px;
                    display: flex;
                    svg {
                        width:26px;
                    }
                }
                .poi-item {
                    width: 322px;
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    margin: 0 auto;
                    padding: 0;
                    padding-bottom: 19px;
                    .info {

                    }
                    .title {
                        font-size: 19px;
                        font-weight: 700;
                        line-height: 24px;
                        max-width: 250px;
                    }
                    .address {
                        font-size: 13px;
                        margin-bottom: 0px;
                    }
                    .schedule {
                        font-size: 13px;
                        width: 266px;
                    }
                    .icon-poi {
                        width:45px;
                        height: 45px;
                        border-radius: 50%;
                        position: relative;
                        margin-right:10px;
                        display: none;
                        svg {
                            width: 26px;
                            margin: 0 auto;
                            position: absolute;
                            top:50%;
                            left:50%;
                            transform: translate(-50%,-50x);
                        }
                    }
                    .icon-83 {
                        background-color: var(--turism-color);
                    } 
                    .icon-80 {
                        background-color: var(--nature-color);
                    }
                    .icon-81 {
                        background-color: var(--sport-color);
                    }
                    .icon-82 {
                        background-color: var(--culture-color);
                    }
                }
    
    
            }
    
            .right-column {
                width:373px;
                padding-left: 30px;
                .logos {
                    display: flex;
                    justify-content: space-around;
                    //padding-top: 38px;
                    align-items: center;
    
                    .simpl-logo {
                        width:100%;
                        max-width:56px;
                        height:auto;
                    }
                    .artrebel-logo {
                        width:100%;
                        max-width:30px;
                        height:auto;
                    }
    
                    .feelSlo-logo {
                        width:100%;
                        max-width:36px;
                        height:auto;
                    }
    
                    .evropska-logo {
                        width:100%;
                        max-width:42px;
                        height:auto;
                    }
                    .roglaPohorje-logo {
                        width:100%;
                        max-width: 32px;
                        height:auto;
                    }
                }
                .images {
                    display: flex;
                    flex-direction: column;
                    gap: 26px;
                    height: 639px;
                    padding-top: 40px;
                    padding-bottom: 26px;
                    align-items: stretch;
    
                    .bgi {
                        height: 100%;
                        background-repeat: no-repeat;
                    }
                }
            }
        }
    }

    &.show {
        display: block;
    }
}
</style>