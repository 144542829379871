<template>
  <div>
          <Header :setupHeader="setupDataHeader"></Header>
    <div class="container-pedestrian">
        <h3 class="main-title">
          <img @click="router.push('/')" :src="leftArrow" alt="" />
          {{ t('counter.title') }}
        </h3>
        <div class="content">
          <div class="all">
            <div class="caption">{{ t('counter.allPedestrians') }}</div>
            <div class="counter">{{formatToMillion(usePedestrianStore().all,12)}}</div>
          </div>
          <div class="line"></div>
          <div class="content-ranges">
            <div class="item">
              <div>
              <div class="caption">{{ t('counter.dailyPedestrians') }}</div>
              <div class="counter">{{formatToMillion(usePedestrianStore().today)}}</div>
            </div>
            </div>
            <div class="item">
              <div>
              <div class="caption">{{ t('counter.monthlyPedestrians') }}</div>
              <div class="counter">{{formatToMillion(usePedestrianStore().month)}}</div>
            </div>
            </div>
            <div class="item">
              <div>
              <div class="caption">{{ t('counter.yearlyPedestrians') }}</div>
              <div class="counter">{{formatToMillion(usePedestrianStore().year)}}</div>
            </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted,onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n';
import { usePedestrianStore,useMapStoreData } from '@/stores/index'
import Header from '@/components/Header.vue'
import { useRouter } from 'vue-router'
import leftArrow from "@/assets/presentation/left-arrow.svg";

const router = useRouter();
const { t } = useI18n();

const setupDataHeader = {
  centerIcon: true,
  orderFilter: false,
  search: false,
  backArrow: false,
  backArrowMap: true,
  shortHeader: false
}

onBeforeMount(() => {
  //usePedestrianStore().refresh();
  useMapStoreData().hideMap = true;
  useMapStoreData().showMap = false;  
})
onMounted(() => {
  /*setTimeout(() => {
    preventClick.value = false;
  }, 300);*/
});

function formatToMillion(number,numberFormat = 9) {
    // Ensure the number is an integer and pad it with leading zeros
    let paddedNumber = String(number).padStart(numberFormat, '0');
    
    // Format with periods separating every three digits
    let formattedNumber = paddedNumber.replace(/(\d{3})(?=\d)/g, '$1.');

    return formattedNumber;
}

</script>
<style  scoped lang="scss">
@import '../../assets/style/mixins.scss';

.container-pedestrian {
  //max-width: get-vw(1500px);
  width: 100%;
  height: calc(100vh - 120px);
  background-color: #fff;
  margin: 0 auto;
  padding: 0 get-vw(220px) get-vw(80px) get-vw(220px);
  overflow-y: auto;

  .main-title {
    color: #2E3D45;
    font-size: get-vw(47px);
    font-weight: 500;
    display: inline-block;

    img {
      margin-right: get-vw(15px);
      width: get-vw(42px);
    }

    &:after {
      content: "";
      display: block;
      width: get-vw(550px);
      height: get-vw(8px);
      margin-top: get-vw(20px);
      background-color: #2E3D45;
    }
  }

  .content {
    margin-top: get-vw(73px);

    .all {
      .caption {
        font-size: get-vw(40px);
      }

      .counter {
        font-size: get-vw(250px);
        line-height: get-vw(250px);
        margin-left: get-vw(20px);
      }
    }

    .content-ranges {
      width: 100%;
      display: flex;

      .item {
        flex: 1 1 0px;
        display: flex;
        flex-direction: column;
        justify-content: center; /* Centers vertically */
        align-items: center; /* Centers horizontally */

        .caption,
        .counter {
          align-self: flex-start; /* Keeps text aligned to the left */
        }

        .caption {
          font-style: italic;
          font-size: get-vw(20px);
        }

        .counter {
          font-size: get-vw(80px);
          line-height: get-vw(80px);
        }
      }
    }
  }

  .line {
    display: block;
    width: get-vw(550px);
    height: get-vw(8px);
    margin-top: get-vw(60px);
    margin-bottom: get-vw(100px);
    background-color: #2E3D45;
  }
}

@media only screen and (orientation: portrait) {

  .container-pedestrian  {
    .line {
      width:get-vw(550px,1080);
      margin-top: get-vw(75px);
    }
    padding: get-vw(320px) get-vw(100px) 0 get-vw(100px);
    max-width: 100%;
    .main-title {
      text-transform: uppercase;
      font-size: get-vw(80px);
      img {
        width: get-vw(70px);
        margin-right: get-vw(30px);
      }
      &:after {
        content:"";
        width: get-vw(550px,1080);
      }
    }
    .content  {
      margin-top: get-vw(160px);
      .all .caption {
        font-size: get-vw(60px);
        margin-bottom: get-vw(20px);
      }
      .all {
        .counter {
          margin-left: get-vw(30px,1080);
        }
      }
      .content-ranges {
        display: block;
        padding-top:get-vw(5px);
        .item  {
          display: block;
          padding-bottom: get-vw(110px);
          .caption {
            font-size: get-vw(40px);
            margin-bottom: get-vw(30px);
            margin-top:get-vw(100px);
          }
          .counter {
            font-size: get-vw(100px,1080);
            line-height: get-vw(100px,1080);
          }
        }
      }
    } 
  }
}

</style>