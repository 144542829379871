<template>
    <div class="container">
        <Header :setupHeader="setupDataHeader"></Header>
        <div class="gallery swiper-container">
            <div class="swiper-wrapper">
                <div class="slide swiper-slide" v-for="(item, index) in useMapStoreData().routes[id].content.media" :key="index">                    
                    <div class="side-content" :class="{minimized:isMinimized}">
                        <h1 class="title">{{ t(`${item.title}`) }}</h1>
                        <p v-if="item.description != undefined">{{ t(`${item.description}`) }}</p>
                        <div class="options">
                            <svg v-if="item.description != undefined" @click="toggleMinimize()" class="minimize"
                                width="28"
                                height="27"
                                viewBox="0 0 28 27"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                d="M8.84375 17.5C9.60547 17.5 10.25 18.1445 10.25 18.9062V25.4688C10.25 26.2891 9.60547 26.875 8.84375 26.875C8.02344 26.875 7.4375 26.2891 7.4375 25.4688V20.3125H2.28125C1.46094 20.3125 0.875 19.7266 0.875 18.9062C0.875 18.1445 1.46094 17.5 2.28125 17.5H8.84375ZM19.1562 10C18.3359 10 17.75 9.41406 17.75 8.59375V2.03125C17.75 1.26953 18.3359 0.625 19.1562 0.625C19.918 0.625 20.5625 1.26953 20.5625 2.03125V7.1875H25.7188C26.4805 7.1875 27.125 7.83203 27.125 8.59375C27.125 9.41406 26.4805 10 25.7188 10H19.1562ZM8.84375 0.625C9.60547 0.625 10.25 1.26953 10.25 2.03125V8.59375C10.25 9.41406 9.60547 10 8.84375 10H2.28125C1.46094 10 0.875 9.41406 0.875 8.59375C0.875 7.83203 1.46094 7.1875 2.28125 7.1875H7.4375V2.03125C7.4375 1.26953 8.02344 0.625 8.84375 0.625ZM25.7188 17.5C26.4805 17.5 27.125 18.1445 27.125 18.9062C27.125 19.7266 26.4805 20.3125 25.7188 20.3125H20.5625V25.4688C20.5625 26.2891 19.918 26.875 19.1562 26.875C18.3359 26.875 17.75 26.2891 17.75 25.4688V18.9062C17.75 18.1445 18.3359 17.5 19.1562 17.5H25.7188Z"
                                fill="#2E3D45"
                                />
                            </svg>
                        </div>
                    </div>
                    <div class="media">
                        <video ref="mediaRefs" :poster="'repo/' + useMapStoreData().destinationId + '/' + item.thumbnail.hash + '.' + item.thumbnail.ext" v-if="item.ext == 'mp4'"  controlslist="nodownload noplaybackrate" loop disablepictureinpicture preload="auto">
                            <source :src="'repo/' + useMapStoreData().destinationId + '/' + item.hash + '.' + item.ext" />
                        </video>
                        <div  v-else>
                            <div ref="mediaRefs" class="image" :style="{ backgroundImage: 'url(repo/' + useMapStoreData().destinationId + '/' + item.hash + '.' + item.ext + ')' }"></div>
                        </div>
                    </div>
                    {}
                    <div v-if="item.ext == 'mp4'" @click="togglePlay(index)" class="play-pause">
                        <img v-show="!isPlaying" :src="pauseIcon" alt="">
                        <img v-show="isPlaying" :src="playIcon" alt="">
                    </div>
                </div>
            </div>
            <div class="pagination-container">
            <div class="swiper-pagination-custom"></div>
          </div>
        </div>

    </div>
</template>
<script setup>
import { computed,ref,onMounted,nextTick,onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMapStoreData } from '@/stores/index'
import { useRoute } from 'vue-router';
import Header from '@/components/Header.vue'
//swiper
import Swiper, { Pagination, Navigation, Autoplay,EffectFade } from "swiper";
import "swiper/css/bundle";

import pauseIcon from '@/assets/pause-icon.svg';
import playIcon from '@/assets/play-icon.svg';

const id = computed(() => route.params.id);
const route = useRoute();
const { t } = useI18n();
const setupDataHeader = {
  centerIcon: true,
  orderFilter: false,
  search: false,
  backArrow: false,
  backArrowMap: true,
  shortHeader: true
}
//todo maxiniziran samo ko je content
const isMinimized = ref(true);
//
const swiper = ref(null);
const mediaRefs = ref([]);
const isPlaying = ref(false);
const currentVideoPlaying = ref(null);


Swiper.use([Pagination, Navigation, Autoplay,EffectFade]);

onMounted(() => {
    useMapStoreData().hideMap = true;
    let swiperOptions = {
    autoplay: {
      delay: 3000,
      disableOnInteraction: true
      , // stops autoplay when user interacts with the slider
    },
    slidesPerView: 1,
    pagination: {
      el: ".swiper-pagination-custom", // This should match the pagination container in your HTML
      clickable: true,
    },
    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },
    on: {
        slideChange: function() {
        onSlideChange();
        }
    },
    loop:true,
  };

  nextTick(() => {
    swiper.value = new Swiper(".gallery.swiper-container", swiperOptions);
  });

});

const toggleMinimize = () => {
    //todo maxiniziran samo ko je content
    isMinimized.value = !isMinimized.value;
}
const togglePlay = (index) => {
    currentVideoPlaying.value = mediaRefs.value[index];
    
  if (!currentVideoPlaying.value) return;

  if (currentVideoPlaying.value.paused) {
    currentVideoPlaying.value.play();
    isPlaying.value = true;
    
    isMinimized.value = true;
    //swiper.value.params.autoplay.disableOnInteraction = false;
    swiper.value.autoplay.pause();
    //console.log("swiper",swiper);
  } else {
    currentVideoPlaying.value.pause();
    isPlaying.value = false;
    //todo maxiniziran samo ko je content
    //isMinimized.value = false;
    //swiper.value.params.autoplay.disableOnInteraction = true;
    swiper.value.autoplay.resume();
  }
}

const onSlideChange = () => {
    if(currentVideoPlaying.value != null && isPlaying.value == true ) {
        isPlaying.value = false;
        //todo maxiniziran samo ko je content
        //isMinimized.value = false;
        currentVideoPlaying.value.pause();
    }
}


onUnmounted(() => {
    console.log("pizda ti materina");
    useMapStoreData().hideMap = false;
});

</script>
<style scoped lang="scss">
@import '../assets/style/GalleryView.scss';
</style>