import { defineStore } from 'pinia';

import { useSetMap,useAnalyticsStore,useCategoriesStore,useFavoritesStore,useMapStoreData,useScenariosStore } from '@/stores/index'
import {isServerOnline} from '@/utils/offlineHandling'
//import { useRouter } from 'vue-router'
//const router = useRouter();

export const useScreensaverStore = defineStore('screensaverStore', {
        state: () => ({
            router:null as any,
            showSreensaver: false,
            screensaverTimeout: null as any,
            updatesCheckTimeout: null as any,
            analitika: null as any,
            screensaverVideo:'' as string,
            screensaverImage:[] as Array<string>,
            videoPlayingSomewhere: false, 
        }),
        actions: {
            startScreensaver() {
                useFavoritesStore().isOpenPanel = false;
                useAnalyticsStore().stopSession();
                this.router.push(useMapStoreData().defaultRoute);
                this.showSreensaver = true;
                useSetMap().fullMapReset();
                this.resetScreensaverTimer();
                clearTimeout(this.updatesCheckTimeout);
                this.setCheckForUpdates();
             },
            resetScreensaverTimer() {
                let _this = this;
                let tmt = 600000;
                
                //console.log(useMapStoreData().options.options);
                if(useMapStoreData().options.options.screensaver_seconds > 0) tmt = useMapStoreData().options.options.screensaver_seconds * 1000;
                //console.log('screensajver seconds '+ useMapStoreData().options.screensaver_seconds);
                clearTimeout(this.screensaverTimeout);
                this.screensaverTimeout = setTimeout(() => {
                    if(_this.showSreensaver == false) {
                        if(!this.videoPlayingSomewhere) {
                            _this.startScreensaver();
                        }else {
                            _this.stopScreensaverTimer();
                        }
                    }
                }, tmt); 
            },

            setCheckForUpdates(){
                let _this = this;
                useMapStoreData().checkForUpdates();
                this.updatesCheckTimeout = setTimeout(() => {
                  _this.setCheckForUpdates();
                }, 1800000); // 30 minutes
            },


            stopScreensaverTimer() {
                if(this.showSreensaver) {
                    useAnalyticsStore().startSession();
                    useCategoriesStore().resetFilters();
                    useScenariosStore().populateSortedMarkers(useScenariosStore().defaultSorting.key);
                    useFavoritesStore().favorites = [];
                    this.showSreensaver = false;
                    console.log('tle')
                    this.resetScreensaverTimer();
                    clearTimeout(this.updatesCheckTimeout);
                    //console.log('isServerOnline iz stopScreensaverTimer');
                    isServerOnline('https://miza.simpl.guide');
                    if(useSetMap().map != null && useSetMap().map.getSource('favoritesSource') != null){
                        useSetMap().map.getSource('favoritesSource').setData({
                            type: "FeatureCollection",
                            features: []
                        });
                    }



                }
            }
        },    })

