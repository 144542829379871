<template>
  <div 
        class="screensaver overlay" 
        @click="stopScreensaver">
        <canvas ref="canvas" width="400" height="400"></canvas>

      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div  
          class="screensaverImage swiper-slide" 
          v-for="(item, index) in useScreensaverStore().screensaverImage" 
          :key="index"
          :style="{ backgroundImage: 'url(' + item + ')' }"
          >
          <!--<img class="screensaver-logo" :src="screensaverLogo" alt="">-->
          </div>
        </div>
      </div>
      <video v-if="useScreensaverStore().screensaverVideo != ''" autoplay muted loop preload="auto">
          <source :src="useScreensaverStore().screensaverVideo" type="video/mp4">
      </video>
      <div class="counter" v-if="useMapStoreData().destinationId == '24'">
        <div class="number-text">
          <div class="text">
            {{ t('counter.dailyPedestrians') }}
          </div>
          <div class="number">
            {{formatToMillion(usePedestrianStore().today)}}
          </div>
        </div>
        <div class="icon">
          <svg width="29" height="47" viewBox="0 0 29 47" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.2478 4.3998C23.2478 6.87469 21.2557 8.79961 18.9015 8.79961C16.4568 8.79961 14.5553 6.87469 14.5553 4.3998C14.5553 2.01658 16.4568 0 18.9015 0C21.2557 0 23.2478 2.01658 23.2478 4.3998ZM11.4767 18.3325C11.3862 18.3325 11.2957 18.4242 11.2051 18.4242L10.2091 18.8825C8.8509 19.5241 7.76435 20.6241 7.22107 22.0907L7.03998 22.3657C6.4967 23.9239 4.77632 24.6572 3.32758 24.0156C1.78829 23.4656 1.06392 21.724 1.69774 20.2574L1.78829 19.8908C2.96539 17.0492 5.1385 14.7577 7.85489 13.4744L8.8509 13.0161C10.7524 12.1911 12.7444 11.7328 14.827 11.7328C18.9015 11.7328 22.5234 14.2077 24.0627 17.9659L25.4209 21.3574L27.4129 22.3657C28.8616 23.099 29.4049 24.8406 28.6805 26.3072C27.9562 27.7738 26.2358 28.3237 24.7871 27.5904L22.4328 26.3988C21.4368 25.9405 20.7125 25.1156 20.3503 24.1073L19.4448 21.999L17.7244 28.0488L22.1612 32.9985C22.7045 33.5485 23.0667 34.1902 23.2478 34.9235L25.3303 43.3564C25.6925 44.9147 24.6965 46.4729 23.1572 46.8396C21.6179 47.2979 20.0786 46.2896 19.6259 44.7313L17.6339 36.665L11.2957 29.607C9.93746 28.1404 9.39418 26.0322 9.93746 24.1073L11.4767 18.3325ZM6.22506 36.4817L8.48872 30.7986C8.66981 31.0736 8.94145 31.3486 9.12254 31.6236L12.8349 35.6567L11.4767 38.9566C11.2957 39.5066 10.9335 40.0566 10.5713 40.4232L4.95741 46.1063C3.87085 47.2979 1.96938 47.2979 0.882827 46.1063C-0.294276 45.0063 -0.294276 43.0814 0.882827 41.9815L6.22506 36.4817Z" fill="#2E3D45"/>
          </svg>
        </div>
      </div>
  </div>
</template>

<script setup>
import {useScreensaverStore,usePedestrianStore,useMapStoreData} from '@/stores/index'
import screensaverLogo from '@/assets/logo-for-screensaver.svg';
import Swiper, { Autoplay,EffectFade } from "swiper";
import { ref,onMounted,nextTick,onBeforeMount } from "vue";
import "swiper/css/bundle";
import { Rive, Layout } from '@rive-app/canvas';
import { useI18n } from 'vue-i18n';

const screensaverStore = useScreensaverStore();
const swiper = ref(null);
const { t } = useI18n();

Swiper.use([Autoplay,EffectFade]);

let riveAnimation = null;
  
const props = defineProps({
  src: String,
  fit: String,
  alignment: String
});
  
const canvas = ref(null);
onBeforeMount(() => {
    if(useMapStoreData().destinationId == '24') { 
      usePedestrianStore().refresh(60000);
    }
})
onMounted(() => {
  riveAnimation = new Rive({
      canvas: canvas.value,
      src: new URL(`../assets/rive/touch-a0.riv`, import.meta.url).href,
      autoplay: true,
      animations: '1 Touch',
    });

  console.log("card app mounted");
    let swiperOptions = {
    autoplay: {
      delay: 8000,
      disableOnInteraction: false
      , // stops autoplay when user interacts with the slider
    },
    slidesPerView: 1,
    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },
    allowTouchMove: false,
    loop:true,
  };

  nextTick(() => {
    swiper.value = new Swiper(".screensaver .swiper-container", swiperOptions);
  });
  /*
  if(useMapStoreData().destinationId == '24') { 
    setInterval(() => {
      if(useMapStoreData().isOnline) {
        usePedestrianStore().refresh(); 
      }
    }, 60000); // every 1 minutes
}
*/
});
const stopScreensaver = () =>  {
    screensaverStore.stopScreensaverTimer();
}

function formatToMillion(number,numberFormat = 9) {
    // Ensure the number is an integer and pad it with leading zeros
    let paddedNumber = String(number).padStart(numberFormat, '0');
    
    // Format with periods separating every three digits
    let formattedNumber = paddedNumber.replace(/(\d{3})(?=\d)/g, '$1.');

    return formattedNumber;
}

</script>
<style scoped lang="scss">
@import '../assets/style/mixins.scss';
@import '../assets/style/components/screensaver.scss';
</style>
