import { defineStore } from 'pinia';
import {useMapStoreData} from '@/stores/index';
import { getI18n } from '@/main';

export const useScenariosStore = defineStore('scenariosStore', {
    state: () => ({
        sortedMarkers: [] as { key: string; value: { properties: any } }[],
        defaultSorting :{key:'Alphabetical',localization:'sortingdropdown.alphabetical'},
        dropdownSortingOptions: [{key:'Alphabetical',localization:'sortingdropdown.alphabetical'}, 
                                {key:'Latest',localization:'sortingdropdown.latest'},
                                ]
    }),
    actions: {
        populateSortedMarkers(option: any) {
            switch(option) {
                case 'Latest':
                    this.sortedMarkers = this.populateSortedMarkersNew();
                    break;
                case 'Alphabetical':
                    this.sortedMarkers = this.populateSortedMarkersAlphabetical();
                    break;
                case 'Favorites':
                    this.sortedMarkers = this.populateSortedMarkersFavorites();
                    break;
                default:
                    this.sortedMarkers = this.populateSortedMarkersAlphabetical();
            }
        },
        populateSortedMarkersAlphabetical() {
            let markersArray = Object.entries(useMapStoreData().markers as Record<string, { properties: any }>).
            map(([key, value]: [string, { properties: any }]) => ({ key, value }));
            markersArray.sort((a, b) => {
                if (a.value.properties.type === 'event' && b.value.properties.type !== 'event') {
                    return -1; // a comes first
                } else if (a.value.properties.type !== 'event' && b.value.properties.type === 'event') {
                    return 1; // b comes first
                } else {
                    const titleA = getI18n().global.t(a.value.properties.id + '.title').trim();
                    const titleB = getI18n().global.t(b.value.properties.id + '.title').trim();
                    return titleA.localeCompare(titleB, useMapStoreData().currentLanguage, { sensitivity: 'base' });
                }
            });
            return markersArray;
        },
        populateSortedMarkersNew() {
            let markersArray = Object.entries(useMapStoreData().markers as Record<string, { properties: any }>).
            map(([key, value]: [string, { properties: any }]) => ({ key, value }));

            markersArray.sort((a, b) => {
                let datea:any = new Date(a.value.properties.dt);
                let dateb:any = new Date(b.value.properties.dt);
                return datea - dateb;
            });
            return markersArray;
        },
        populateSortedMarkersFavorites() {
            let markersArray = Object.entries(useMapStoreData().markers as Record<string, { properties: any }>).
            map(([key, value]: [string, { properties: any }]) => ({ key, value }));

            markersArray.sort((a, b) => {
                let significancea:any = a.value.properties.json.significance;
                let significanceb:any = b.value.properties.json.significance;
                // check which has bigger string number
                if(significancea > significanceb) {
                    return -1;
                } else if(significancea < significanceb) {
                    return 1;
                } else {
                    return 0;
                }
            });
            return markersArray;
        }
    }
});