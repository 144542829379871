<template>
  <div class="app-container">
    <Screensaver v-if="mapStore.appReady" v-show="screensaverStore.showSreensaver"></Screensaver>
    <div class="app-clicklistener" @click="appClickListener()">
      <LanguageChanger uniqueClass="screensaver-lang" class="language-switcher-screensaver" v-show="screensaverStore.showSreensaver"></LanguageChanger>
      <RouterView v-slot="{ Component }">
    <Transition v-if="!isIframeRoute" name="fade" mode="out-in">
      <component :is="Component" :key="$route.fullPath"  />
    </Transition>
    <Transition name="iframe-transition" v-else>
    <component :is="Component" :key="$route.fullPath" />
  </Transition>
  </RouterView>
  <Poisfilters v-show="mapStore.showMap || !pathRoute"></Poisfilters>
  <FavoritesList v-show="mapStore.showMap || !pathRoute" />
  <MapView v-show="mapStore.appReady"></MapView>
  <printingTemplate v-if="useMapStoreData().destinationId == '1'"></printingTemplate>
  <offlineTag v-if="!isOnline"></offlineTag>
  <ptujBranding v-if="useMapStoreData().destinationId == '17'"></ptujBranding>
 <!-- <WishingWellAnim v-if="whishingWellRoute"></WishingWellAnim>-->
  </div>
    <p class="stayingActiveCounter">{{ stayingActiveCounter }}</p>
  </div>
</template>

<script setup>

import { computed,onBeforeMount,ref , onMounted, watchEffect } from 'vue';
import { RouterView,useRoute,useRouter } from 'vue-router'
import {useMapStoreData,useScreensaverStore,useAnalyticsStore,useCategoriesStore,usePedestrianStore} from '@/stores/index'
import Poisfilters from "@/components/Poisfilters.vue";
import FavoritesList from "@/components/FavoritesList.vue";
import LanguageChanger from './components/LanguageChanger.vue';
import MapView from '@/views/MapView.vue'
import Screensaver from '@/components/Screensaver.vue'
import printingTemplate from '@/components/printing/printingTemplate.vue'
import offlineTag from '@/components/OfflineTag.vue'
import ptujBranding from "@/components/branding/PtujBranding.vue";
//import WishingWellAnim from '@/components/WishingWellAnim.vue'
import { useI18n } from "vue-i18n";


const mapStore = useMapStoreData();
const route = useRoute();
const router = useRouter();
const screensaverStore = useScreensaverStore();
const analyticsStore = useAnalyticsStore();
const { t } = useI18n();
const stayingActiveCounter = ref(0);
const pathRoute = computed(() => route.path !== '/scenarios/');
const whishingWellRoute = computed(() => route.path == '/wishingWell');
const isOnline = ref(true);
const isIframeRoute = computed(() => route.name === 'iframe')
const appClickListener = () => {
  screensaverStore.resetScreensaverTimer();
}

onMounted(() => {

  if(mapStore.defaultRoute == '/map/') mapStore.showMap = true;
  router.push(mapStore.defaultRoute);
  screensaverStore.resetScreensaverTimer();


});


onBeforeMount(() => {
  //analyticsStore.setupAnalytics();
  analyticsStore.startSession();
  screensaverStore.router = router;
  useCategoriesStore().translator = t;

  setInterval(() => {
    stayingActiveCounter.value = stayingActiveCounter.value + 1;
  }, 5 * 60 * 1000); // every 5 minutes


  // Check the initial online status
if (navigator.onLine) {
  //useMapStoreData().isOnline = true;
  console.log('You are online!');
  isOnline.value = true;
} else {
  //useMapStoreData().isOnline = false;
  isOnline.value = false;
  console.log('You are offline!');
}

// Listen for online status changes
window.addEventListener('online',  function() {
  useMapStoreData().isOnline = true;
  console.log('You are back online!');
  isOnline.value = true;
});

window.addEventListener('offline', function() {
  useMapStoreData().isOnline = false;
  isOnline.value = false;
  console.log('You have lost internet connection.');
});

});

</script>
<style scoped lang="scss">
  @import 'assets/style/mixins.scss';
.app-container {
  background-color: #fff;
}

.language-switcher-screensaver {
  position: absolute;
  bottom:2.5vw;
  left:2.5vw;
}

.stayingActiveCounter {
  position:absolute;
  top:-999999999vh;
}

@media only screen and (orientation: portrait) {

  .language-switcher-screensaver {
  position: absolute;
  bottom:50%;
  left:2.5vw;
}
}

.iframe-transition-enter-active,
.iframe-transition-leave-active {
  transition: opacity 0.3s ease;
}
.iframe-transition-enter-from,
.iframe-transition-leave-to {
  opacity: 0;
}
</style>
