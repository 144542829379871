import { defineStore } from 'pinia';
import {useFavoritesStore,useMapStoreData,useMarkersStore} from '@/stores/index';

export const usePrintingStore = defineStore('printingStore', {
    state: () => ({
        printMap: null as any,
        mapImage: null as any,
        expressionMarkerPart: [] as any,
        pathName: null as any,
        printMarkersSource: null as any,
    }),
    actions: {
        async setUpTemplate() {
          let _this = this;
          this.expressionMarkerPart = [];
          let templateMarkerData = [];

          for (let item of useFavoritesStore().favoritesGeoJson) {
            let id:any = item.properties.id;

            if (item.properties.hasOwnProperty('id')) {
              let markerData = (useMapStoreData().markers as any)[id];
              let category = markerData.properties.categories[0] + "-" + markerData.properties.categories[1];
              let imageCategory = useMarkersStore().categoryMarkersLook.get(category);
              
              templateMarkerData.push({
                title: markerData.properties.title,
                address: markerData.properties.address,
                opening_hours: markerData.properties.opening_hours,
                categoryImage: imageCategory,
                image:useMarkersStore().getMarkerImage(id,'m')
              });
            }
          }
          _this.printMarkersSource = templateMarkerData

        },
    }
});