<template>
    <div class="flag">
        <canvas ref="canvas"></canvas>
    </div>
</template>

<script setup>
import { ref,onMounted } from "vue";
import "swiper/css/bundle";
import { Rive } from '@rive-app/canvas';

let riveAnimation = null;
const canvas = ref(null);

onMounted(() => {
    riveAnimation = new Rive({
      canvas: canvas.value,
      src: new URL(`../assets/rive/logo-icon-anim-a0.riv`, import.meta.url).href,
      autoplay: true  
    });
});

</script>
<style scoped lang="scss">
@import '../assets/style/mixins.scss';
.flag {
    canvas {
        width:get-vw(350px);
        height:auto;
    }
}
</style>