<template>
        <Transition>
    <div class="container"  v-show="!mapStore.showMap" >
      <div class="home-container">
       <!-- <div 
        v-show="useMapStoreData().hideMapFromHome"
        :style="{ 
          backgroundImage: `url(${mapStore.mapImage})`,
          marginLeft:'-2.34375vw' 
        }" 
        class="menu-item map" 
        @click="goToWishingWell()"
        data-cy="menu-item-map"
        >
          <div class="holder">
            <div class="text">
              <div class="align">
                Wishing well
              </div>
            </div>
          </div>
        </div>-->



        <div 
        v-show="useMapStoreData().hideMapFromHome"
        :style="{ 
          backgroundImage: `url(${mapStore.mapImage})`,
          marginLeft:'-2.34375vw' 
        }" 
        class="menu-item map" 
        @click="hideHome()"
        data-cy="menu-item-map"
        >
          <div class="holder">
            <div class="text">
              <div class="align">
                {{ t('landing_page.map.title') }}
              </div>
            </div>
          </div>
        </div>

        <div 
        :style="{ 
          backgroundImage: `url(${mapStore.scenariosImage})`, 
          marginLeft:'-2.34375vw',
          borderRadius: mapStore.routes.length == 0 ? '0' : ''  
        }" 
          class="menu-item poi"
          @click="navigateTo('/scenarios')"
          data-cy="menu-item-poi"
          >
          <!--<router-link to="/scenarios"></router-link>-->
          <div class="holder">
            <div class="align">
              <div class="text">{{ t('landing_page.scenarios.title') }}</div>
            </div>
          </div>
        </div>
        <div v-for="(item,index) in mapStore.routes" 
         v-show="shouldShowItem(item)"
        :style="{ 
          backgroundImage: backgroundImageUrl(item),
          borderRadius: index === mapStore.routes.length - 1 ? '0' : ''  
        }" class="menu-item custom-items"
        @click="navigateTo(item.type,index)"
        >
          <div class="holder">
            <div class="text">
              <div class="align">
                {{ t(`${item?.title}`) }}
              </div>
            </div>
          </div>
        </div>
        <div class="checkImages"></div>
      </div>
      <div id="debug" style="position: absolute; z-index: 100; font-size: 20px"></div>

  </div>
</Transition>
</template>

<script setup>
import {ref, computed,onMounted } from 'vue';
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n';
import {useMapStoreData,useMarkersStore} from '@/stores/index'
import loader from '@/assets/LOAD2.png'


const { t } = useI18n();

const mapStore = useMapStoreData();
const markersStore = useMarkersStore();
const router = useRouter();
const preventClick = ref(true);

const backgroundImageUrl = (item) => {
    return `url(${mapStore.publicUrl}repo/${mapStore.destinationId}/${item.thumb.hash}.${item.thumb.ext})`;
}

const hideHome = () => {
  mapStore.hideMap = false;
  mapStore.showMap = true;
}

const goToWishingWell = () => {
  mapStore.hideMap = true;
  router.push('wishingWell');
}

const navigateTo = (path,index) => {
  if(preventClick.value) return;
  index = index === undefined ? '' :  index;
  router.push(path + '/' +index);
}

onMounted(() => {
  setTimeout(() => {
    preventClick.value = false;
  }, 300);
});
  const shouldShowItem = computed(() =>{
    return (item) => {
      if(item.type == "iframe") {
        if(useMapStoreData().isOnline) {
          return true;
        }else {
          return false;
        }  
      }else {
        return true
      }

    };
  });

</script>
<style scoped lang="scss">
@import '../assets/style/mixins.scss';
@import '../assets/style/HomeView.scss';
.loading {
      .progress-circle {
      max-width: 100px;
      margin: 20px;
    }
    svg {
      width: 100%;
      height: auto;
      transform: rotate(-90deg);
    }
    .circle-bg {
      fill: none;
      stroke: #eee;
      stroke-width: 3.8;
    }
    .circle {
      fill: none;
      stroke-width: 3.8;
      stroke-linecap: round;
      stroke: #f44336;
      transition: stroke-dasharray 0.35s ease;
    }
    .percentage {
      fill: #f44336;
      font-size: 0.5em;
      text-anchor: middle;
      dominant-baseline: middle;
    }
}
</style>